<div class="main-wrapper">
  <app-navbar></app-navbar>
  <div class="page-wrapper">
    @if (isUserLoggedIn && isTermsAccepted) {
      <app-sidebar userRoles [role]="allowedRoles"></app-sidebar>
    }
    <div
      class="content-wrapper"
      [class]="{
        'gap-0': !isUserLoggedIn,
      }"
      [style.padding]="!isUserLoggedIn ? '0' : ''"
    >
      <app-notifications></app-notifications>

      <div [class]="{ 'page-padding': isUserLoggedIn, 'h-full': !isUserLoggedIn }">
        <router-outlet> </router-outlet>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
