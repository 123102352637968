import { AfterViewInit, Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { UserGroups } from '../../shared/models/user.model';
import { UserGroupService } from '../../shared/service/user-group.service';
import { BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [RouterOutlet, BreadcrumbsComponent],
  templateUrl: './admin.component.html',
})
export class AdminComponent implements AfterViewInit {
  constructor(
    private router: Router,
    private userGroupService: UserGroupService
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.navigateToAppropriateRoute();
    });
  }

  private navigateToAppropriateRoute(): void {
    const currentUrl = this.router.url;

    // Check it is the landing route from cognito
    if (currentUrl === '/admin') {
      this.redirectBasedOnUserGroups();
    } else {
      // for page refresh, navigate to the current route
      this.router.navigate([currentUrl]);
    }
  }

  private redirectBasedOnUserGroups(): void {
    if (this.hasGroupAccess([UserGroups.CLIENT_ADMIN, UserGroups.SUPER_ADMIN, UserGroups.DEVELOPER])) {
      this.router.navigate(['/admin/users'], { replaceUrl: true });
    } else if (this.hasGroupAccess([UserGroups.PRODUCT_ADMIN])) {
      this.router.navigate(['/admin/products'], { replaceUrl: true });
    } else {
      this.router.navigate(['/admin/access-denied'], { replaceUrl: true });
    }
  }

  private hasGroupAccess(groups: UserGroups[]): boolean {
    const userGroups = this.userGroupService.getUserGroups();
    return groups.some((group) => userGroups.includes(group));
  }
}
